.buttonMetamask {
  background-color: rgb(133,27,246,0.6);
  display: inline-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 0px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
}

.divMetamask {
  margin-right: 10px;
  padding: 0 10px;
  border-radius: 2px;
}

.spanMetamask {
  padding: 10px 10px 10px 0px;
  font-weight: 500;
}
